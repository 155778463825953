import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import get from "lodash/get"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import CenterColumn from "../components/CenterColumn"
import LongtextMarkdown from "../components/LongtextMarkdown"
import { FiftyFifty } from "../components/ReponsiveLayouts"
import { Typography, Button } from "@mui/material"
import { Box } from "@mui/system"

const StorageTypeTemplate = props => {
  const storage = get(props, "data.contentfulStorageType")
  const siteTitle = get(props, "data.site.siteMetadata.title")

  return (
    <Layout
      breadcrumbs={[{ url: "/piecetypes-and-storage", title: "Piecetypes" }]}
    >
      <Helmet title={`${storage.title} | ${siteTitle}`} />
      <CenterColumn>
        <FiftyFifty>
          {storage.primaryMedia && (
            <Box flush>
              <GatsbyImage
                image={storage.primaryMedia.gatsbyImageData}
                alt={storage.title}
              />
            </Box>
          )}
          <Box>
            <h2>{storage.title}</h2>
            <LongtextMarkdown value={storage.description} />
            <Button variant="outlined" href={storage.productLink}>
              View Product
            </Button>
            <Typography variant="h3">Bins</Typography>
            <LongtextMarkdown value={storage.binSummary} />
          </Box>
        </FiftyFifty>
        <h3>Description</h3>
        <LongtextMarkdown value={storage.fullDescription} />
      </CenterColumn>
    </Layout>
  )
}

export default StorageTypeTemplate

export const pageQuery = graphql`
  query StorageTypeBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulStorageType(slug: { eq: $slug }) {
      title
      id: contentful_id
      description {
        childMarkdownRemark {
          html
        }
      }
      productLink
      fullDescription {
        childMarkdownRemark {
          html
        }
      }
      binSummary {
        childMarkdownRemark {
          html
        }
      }
      primaryMedia {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
