import { Grid } from "@mui/material"
import React from "react"
import { Children, cloneElement } from "react"

export const FiftyFifty = ({ children, ...otherProps }) => {
  const arrayChildren = Children.toArray(children)

  return (
    <Grid container {...otherProps}>
      {Children.map(arrayChildren, (child, index) => (
        <Grid
          item
          xs={12}
          md={6}
          sx={!child.props.flush ? { px: 2 } : undefined}
        >
          {cloneElement(child)}
        </Grid>
      ))}
    </Grid>
  )
}
