import React from "react"
import { Box } from "@mui/system"
import { Typography } from "@mui/material"
import styled from "@emotion/styled"

const LongtextMarkdown = ({ value }) => {
  const MarkdownStylesWrapper = styled(Box)(({ theme }) => ({
    "& a": {
      color: theme.palette.primary.contrast,
    },
    "& table": {
      width: "100%",
      "& thead": {
        backgroundColor: "#eee",
        "& th": {
          textAlign: "left",
          padding: theme.spacing(1),
        },
      },
      "& td": {
        padding: theme.spacing(1),
        backgroundColor: "#eee",
      },
    },
  }))

  return (
    value && (
      <Box>
        <Typography variant="content">
          <MarkdownStylesWrapper
            dangerouslySetInnerHTML={{
              __html: value.childMarkdownRemark.html,
            }}
          />
        </Typography>
      </Box>
    )
  )
}
export default LongtextMarkdown
